import axios from 'axios';
import {
  setAuthToken,
  URL,
} from './index';

export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR';

export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_ERROR = 'GET_PROJECT_ERROR';

export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';

export const EDIT_PINNED_PROJECT = 'EDIT_PINNED_PROJECT';
export const REORDER_PROJECTS = 'REORDER_PROJECTS';
export const INVALIDATE_PROJECT = 'INVALIDATE_PROJECT';

export const ADD_PROJECT_ATTACHMENTS = 'ADD_PROJECT_ATTACHMENTS';
export const DELETE_PROJECT_ATTACHMENTS = 'DELETE_PROJECT_ATTACHMENTS';

export const UPSERT_PROJECT_CUSTOM_FIELD = 'UPSERT_PROJECT_CUSTOM_FIELD';
export const DELETE_PROJECT_CUSTOM_FIELD = 'DELETE_PROJECT_CUSTOM_FIELD';

export const searchProjects = async (params) => {
  setAuthToken(localStorage.getItem('token'));

  try {
    const res = await axios.post(`${URL}/projects/search`, params);
    return res.data;
  } catch ({ response }) {
    throw response && response.data;
  }
};

export const getProjects = (productId, filters = []) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_PROJECTS });
      const res = await axios.post(`${URL}/projects/search`, {
        limit: 50000,
        fields: [
          '_id',
          'name',
          'projectNumber',
          'order',
          'productId',
          'createdAt',
          'status',
        ],
        filters: [
          {
            field: 'archived',
            comparisonOperator: 'eq',
            value: false,
          },
          ...productId ? [
            {
              field: 'productIds',
              comparisonOperator: 'in',
              value: [productId],
            },
          ] : [],
          ...filters,
        ],
      });
      dispatch({
        type: GET_PROJECTS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_PROJECTS_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const getProject = (projectId, modifyLoading) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      if (modifyLoading) {
        dispatch({ type: GET_PROJECT });
      }

      const res = await axios.get(`${URL}/projects/${projectId}`);
      dispatch({
        type: GET_PROJECT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_PROJECT_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const addProject = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/projects`, data);
      dispatch({
        type: CREATE_PROJECT_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editProject = ({
  projectId,
  name,
  projectNumber,
  status,
}, withoutRefresh) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/projects/${projectId}`, {
        name,
        projectNumber,
        status,
      });
      if (!withoutRefresh) {
        dispatch({
          type: EDIT_PROJECT_SUCCESS,
          payload: res.data,
        });
      }
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const archiveProject = ({ projectId, archived }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.patch(`${URL}/projects/${projectId}`, {
        archived,
      });
      dispatch({
        type: DELETE_PROJECT_SUCCESS,
        payload: projectId,
      });
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const orderProjects = (projectId, order) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/projects/${projectId}`, {
        order,
      });

      dispatch({
        type: REORDER_PROJECTS,
        payload: {
          projectId,
          order,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const pinProjectToggle = (pinnedProject) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.patch(`${URL}/users/current`, { pinnedProject });
      dispatch({
        type: EDIT_PINNED_PROJECT,
        payload: pinnedProject,
      });
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addProjectAttachment = (projectId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/projects/${projectId}/attachments`, data);
      dispatch({
        type: ADD_PROJECT_ATTACHMENTS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteProjectAttachment = ({ projectId, attachmentId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/projects/${projectId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_PROJECT_ATTACHMENTS,
        payload: attachmentId,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const upsertProjectCustomField = (projectId, customField) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.put(`${URL}/projects/${projectId}/custom-fields`, customField);
      dispatch({
        type: UPSERT_PROJECT_CUSTOM_FIELD,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteProjectCustomField = (projectId, customFieldValueId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.delete(`${URL}/projects/${projectId}/custom-fields/${customFieldValueId}`);
      dispatch({
        type: DELETE_PROJECT_CUSTOM_FIELD,
        payload: {
          projectId,
          customFieldValueId,
        },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const invalidateProject = () => async (dispatch) => {
  dispatch({
    type: INVALIDATE_PROJECT,
  });
};
